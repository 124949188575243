import React from 'react';
import TilesBg from "../media/TilesBg.svg";
import { useNavigate } from 'react-router-dom';
import PromotionPopup from '../compements/PromotionPopup.js';
function Tile(props) {
  console.log(props)
    const navigate = useNavigate();
    function goToNextPage(){
      // const texTareaPrompt = document.querySelector(`.prompt-${props.index}`).value
      const prompt = {
          heading: props.heading, 
          prompt: props.prompt,
          userData: props.userData
      }
      navigate('/results', { state: prompt })
    }
  return (
    <div className='w-[100%] md:w-[45%] lg:w-[30%] bg-cover bg-no-repeat bg-center flex flex-col items-center p-[30px] gap-[15px] rounded-[20px]' style={{backgroundColor: `${props.color}`, backgroundImage: `url(${TilesBg})`}}>
        <img src={props.image} className='h-auto max-w-full'/>
        <div>
            <h1 className='text-white text-center font-poppins text-[25px] font-bold'>{props.heading}</h1>
            <p className='text-center font-poppins text-[15px] text-white m-0'>{props.content}</p>
            {/* <textarea className={`prompt-${props.index} w-full`} defaultValue = {props.prompt}/> */}
        </div>
        {props.popup ? 
        <><PromotionPopup button = {props.button} heading = {props.heading} /></>
        :
        <>
        <button className='font-poppins text-[15px] font-semibold rounded-full bg-white py-[10px] px-[30px]' onClick={()=> {goToNextPage()}}>{props.button}</button>
        </>
        }
    </div>
  )
}

export default Tile
