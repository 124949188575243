// import React, { useState } from 'react';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Formio } from 'formiojs';
import { useNavigate } from 'react-router-dom';
import "../styles/Popup.css"

// Modal style configuration (you can adjust the styles as needed)
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Bind modal to the root element of your app
Modal.setAppElement('#root');

const ProductPromoFormModal = (propsB) => {
    const navigate = useNavigate();
    const [submissionData, setSubmissionData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState(null);
    var subData = {};
    const getStoredData = () => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
          return JSON.parse(savedData); // Parse the string back into an object
        }
        return null; // Return null if no data is found
      };
    const generatePrompt = (props) => {
        return {
            heading: propsB.heading,
            prompt: `**Product Promotion**: Each ad will be crafted to maximize variety in length and style, including 1 long ad rich in detail and 1 medium-length ads with unique messaging styles. Ads are provided in sets of two and the final ad in each set is the longest, Ads avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            userData: subData,
        }
    }
    // Open the modal
    const openModal = () => {
        setModalIsOpen(true);
    };

    // Close the modal
    const closeModal = () => {
        setModalIsOpen(false);
    };

    // Form.io form JSON (same as provided)
    const formJSON = {
        "display": "form",
        "components": [
            {
                "type": "columns",
                "columns": [
                    {
                        "components": [
                            {
                                "label": "Product Name",
                                "placeholder": "Enter the product name",
                                "type": "textfield",
                                "key": "productName",
                                "input": true,
                                "tableView": true,
                                "validate": {
                                    "required": true,
                                }
                            }
                        ],
                        "width": 6,
                    },
                    {
                        "components": [
                            {
                                "label": "Product URL",
                                "placeholder": "Enter the product URL",
                                "type": "textfield",
                                "key": "productURL",
                                "input": true,
                                "validate": {
                                    "required": true,
                                    "pattern": "^https?://.*$",
                                    "customMessage": "Please enter a valid URL starting with http or https."
                                },
                                "tableView": true
                            }
                        ],
                        "width": 6,
                    }
                ],
            },
            {
                "type": "columns",
                "columns": [
                    {
                        "components": [
                            {
                                "label": "Product Description",
                                "placeholder": "Enter the product description",
                                "type": "textarea",
                                "key": "productDescription",
                                "input": true,
                                "tableView": true,
                                "validate": {
                                    "required": true,
                                }
                            }
                        ],
                        "width": 6,
                    },
                    {
                        "components": [
                            {
                                "label": "Key Features",
                                "placeholder": "Enter the product's Key Features",
                                "type": "textarea",
                                "key": "keyFeatures",
                                "input": true,
                                "validate": {
                                    "required": true,
                                },
                            }
                        ],
                        "width": 6,
                    }
                ],
            },
            {
                "type": "button",
                "label": `${propsB.button}`,
                "input": true,
                "key": "submit",
                "tableView": false
            }
        ]
    }

    // Render the Form.io form inside the modal
    const renderForm = async () => {
        const formElement = document.getElementById('formio');
        if (formElement) {
            // Await the creation of the form
            const form = await Formio.createForm(formElement, formJSON);
            
            // Load saved data from localStorage, if available
            const savedData = JSON.parse(localStorage.getItem('formData'));
            if (savedData) {
                form.submission = { data: savedData }; // Pre-fill the form with saved data
            }

            // Save form data to localStorage on form submit
            form.on('submit', (submission) => {
                setSubmissionData(submission.data);
                saveDataToLocalStorage(submission.data); // Save data
                // navigate('/content'); // Navigate to another page after submission
                subData = submission.data;
                const props = getStoredData();
                const prompt = generatePrompt(props)
                // console.log(prompt)
                navigate('/results', { state: prompt })
                closeModal();
            });

            // Optional: Save form data to localStorage on form change (autosave)
            form.on('change', (submission) => {
                saveDataToLocalStorage(submission.data); // Autosave form data
            });
        }
    };

    // Function to save form data to localStorage
    const saveDataToLocalStorage = (formData) => {
        localStorage.setItem('formData', JSON.stringify(formData));
    };

    return (
        <div>
            {/* Trigger Button to open the modal */}
            <button onClick={openModal} className="font-poppins text-[15px] font-semibold rounded-full bg-white py-[10px] px-[30px]">
                {propsB.button}
            </button>

            {/* Modal */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={renderForm} // Render the Form.io form after the modal opens
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Promotional Content Form"
            >
                <h2 className='text-center text-3xl font-bold my-10 uppercase text-blue'>Enter Product Details</h2>
                {/* Form.io form will be rendered here */}
                <div id="formio" />

                <button onClick={closeModal} className="px-4 py-2 mt-4 bg-red-500 text-white rounded hover:bg-red-700 popup-close-btn">
                    &#10006;
                </button>
            </Modal>
        </div>
    );
};

export default ProductPromoFormModal;
