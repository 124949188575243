import React from 'react';
import Tile from './Tile';
import CarouselImg from '../media/Tiles/CarouselImg.svg';
import CaseStudyImg from '../media/Tiles/CaseStudyImg.svg';
import FactsImg from '../media/Tiles/FactsImg.svg';
import HeadlinesIMG from '../media/Tiles/HeadlinesIMG.svg';
import MythBusterImg from '../media/Tiles/MythBusterImg.svg';
import PromotionalImg from '../media/Tiles/PromotionalImg.svg';
import QuotesImg from '../media/Tiles/QuotesImg.svg';
import Stats from '../media/Tiles/Stats.svg';
import VideoImg from '../media/Tiles/VideoImg.svg';
function Tiles() {
    const getStoredData = () => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
          return JSON.parse(savedData); // Parse the string back into an object
        }
        return null; // Return null if no data is found
      };
      const props = getStoredData();
  const promptPostStructure = {
  "My brand name, and niche I operate in are": `${props.brandNameNiche}`,
  "My target audience, their demographics, pain points, and key frustrations are": `${props.targetAudience}`,
  "My core values and unique selling points (USPs) are": `${props.keyBrandValuesUSP}`,
  "My social media platforms I use are?": `${props.socialMediaPlatforms}`,
  "tone or voice that the content should reflect is": `${props.toneVoiceContent}`,
  "examples of my best-performing posts are.": `${props.brandBioElevatorPitch}`,
  "My main Teaching topics are": `${props.mainTeachingTopics}`,
  "My desired length for written content is": `${props.contentLength}`,
  "post topics for content ideas that resonate with my audience are": `${props.corePostIdeas}`
  }
    const content = {
        tile1: {
            color:"#369FFF",
            heading: "Video Scripts",
            content: "Create engaging video scripts for your content.",
            button: "Write My Script",
            prompt: `**Video Scripts**: Generate video scripts for my brand.Content is created one category at a time, with the GPT generating video scripts in a 400-500 word transcript style for deeper engagement, including engaging language, a clear call to action, and a short accompanying social media post.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${VideoImg}`,
        },
        tile2: {
            color:"#FF993A",
            heading: "Carousel Posts",
            content: "Design captivating carousel posts for social media.",
            button: "Create My Post",
            prompt:`**Carousel Posts**: - Generated 3 facebook carousel posts, with each post containing 5 unique image captions to go along with each cohesive post text. Output should have headings for 'Post Text:' and 'Image Captions:' in h3 tag\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results. Please include an image suggestion for each of the carousel images.`,
            image: `${CarouselImg}`,
        },
        tile3: {
            color:"#8AC53E",
            heading: "Stats/Figure Posts",
            content: "Share compelling stats and figures with ease.",
            button: "Generate My Post",
            prompt: `- **Authority Stats/Facts**: Write 3 Facebook posts about the products offered by the business and each post should contain an image captionstating the statistic or fact for enhanced credibility. Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${Stats}`,
        },
        tile4: {
            color:"#C03EC5",
            heading: "Viral Headlines",
            content: "Craft attention-grabbing viral headlines effortlessly.",
            button: "Get My Headline",
            prompt: `**Viral Headlines**: Do a mix of russell brunson style headlines, output should be strictly just the headlines in a list with h3 tag separated by an hr tag don't generate any content.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${HeadlinesIMG}`,
        },
        tile5: {
            color:"#FF363A",
            heading: "Quotes/Motivation",
            content: "Inspire your audience with motivational quotes.",
            button: "Find My Quote",
            prompt: `**Quotes/Motivation**: Write 3 Facebook posts in a a variety of length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms that contain a quote or motivational message that applies to the product / business / service.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results. `,
            image: `${QuotesImg}`,
        },
        tile6: {
            color:"#FF3AB0",
            heading: "Myth Buster",
            content: "Debunk common myths with simple facts.",
            button: "Bust My Myth",
            prompt: `Write 3 facebook posts that each debunk common myths or misconceptions pertaining to the product or service with simple facts. Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${MythBusterImg}`,
        },
        tile7: {
            color:"#30D8B7",
            heading: "Facts/Education",
            content: "Deliver educational facts and insightful content.",
            button: "Share My Fact",
            prompt: `**Facts/Education**: Create 3 facebook posts each including an image caption stating the statistic or fact for enhanced credibility about my brand. Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${FactsImg}`,
        },
        tile8: {
            color:"#960507",
            heading: "Brand Promotion",
            content: "Create impactful promotional content for your brand.",
            button: "Create My Promo",
            prompt: `**Promotional**: Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms. Outputs posts to be provided in sets of 3.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${PromotionalImg}`,
        },
        // tile9: {
        //     color:"#C5913E",
        //     heading: "Case Study",
        //     content: "Showcase success stories and testimonials easily.",
        //     button: "Write My Case Study",
        //     prompt: `**Case Study**: Generate case studies for my brand. Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
        //     image: `${CaseStudyImg}`,
        // },
        tile10: {
          color:"#cf3917",
          heading: "Product Promotion",
          content: "Create impactful promotional content for your product.",
          button: "Create Promotional Content",
          prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
          prompts: "test",
          popup: true,
          image: `${PromotionalImg}`,
        }

    }
  return (
    <div className='flex gap-5 flex-wrap justify-center'>
        {
            Object.keys(content).map((key, index) => {
              const tile = content[key];
              return (
                <Tile
                  key={key}
                  index={key}
                  color={tile.color}
                  heading={tile.heading}
                  content={tile.content}
                  button={tile.button}
                  prompt={tile.prompt}
                  popup={tile.popup}
                  prompts={tile.prompts}
                  userData={promptPostStructure}
                  image={tile.image}
                />
              );
            })
        }
    </div>
  )
}

export default Tiles
