import React from 'react'
import { useLocation } from 'react-router-dom';
import GenerateResults from '../compements/generatedPdf.js'
function Results() {
    const location = useLocation();
    const prompt = location.state || '';
    const getStoredData = () => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
          return JSON.parse(savedData); // Parse the string back into an object
        }
        return null; // Return null if no data is found
      };
      const storedData = getStoredData();
      // console.log(storedData)
  return (
    <div>
      <GenerateResults userData = {prompt.userData} heading = {prompt.heading} prompt = {prompt.prompt} prompts = {prompt.prompts} {...storedData}/>
    </div>
  )
}

export default Results
